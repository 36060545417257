import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Welcome!</h1>
      <p><b>-Under Construction-</b><br />Please be patient :)</p>
    </div>
  );
}

export default App;
